.ticker-icon {

  > object {
    width: 30px;
    height: 30px;

    display: inline-block;
    position: relative;
    background: radial-gradient(white 50%, rgba(255, 255, 255, 0) calc(75% + 1px), rgba(255, 255, 255, 0) 100%);
    border-radius: 50%;
    box-shadow: black 0px 0px 1px;

    &:first-of-type {
      margin-right: -15px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: black 0px 0px 1px;
      background: white url(data-url:../assets/images/not-found.svg);
    }
  }

}

.invert-pair {
  vertical-align: top;
  margin-left: 0.5em;
  top: 5px;
  font-size: 0.6em;
  position: relative;

  .icon {
    margin-right: 0.3em;
  }
}

.pool-viewer {
  width: 100%;
  overflow: hidden;

  margin-top: 0;

  background: linear-gradient(180deg, #00000029, #0000001a);
  box-shadow:
    inset 0 -1px 2px -1px #ffffff3b,
    inset 0 -1px 2px -1px #0000003b;

  &:not(:empty) {
    padding: 1em;
  }

  .liquidity-tooltip {
    position: absolute;
    background: var(--background-color);
    pointer-events: none;
    display: none;

    &:not(:empty) {
      padding: 0.7em;
    }
  }

  .liquidity-chart{
    max-height: 80vh;

    &:hover + .liquidity-tooltip {
      display: block;
    }
  }
}

.pool-description {
  display: flex;
  place-items: center;
  place-content: space-between;
  margin: 0 auto;
  font-size: 0.9em;

  @include breakpoint($smallish) {
    font-size: 1.2em;
  }

  .label {
    font-size: 1em;
    margin: 0.1em 0;
    display: flex;
    align-self: center;
    align-items: center;

    @include breakpoint($smallish) {
      font-size: 1.5em;
      margin: 0.1em 6em 0.1em 0.1em;
    }

    label {
      margin: 0 0.3em;
    }
  }

  .figures, .prices {
    div:first-of-type {
      margin-bottom: 0.4em;
    }
  }
}

.pool {
  .liquidity-chart {
    margin-top: 2em;
  }
}

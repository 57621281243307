.theme-selector {
  font-size: 2em;

  @include breakpoint($smallish) {
    margin-right: 0.3em;
  }

  .themerator {
    cursor: pointer;

    i {
      color: var(--primary-color);
    }

    &.dark {
      display: block;
    }

    &.dark {
      display: none;
    }

    ._colorscheme-light &.light {
      display: none;
    }

    ._colorscheme-light &.dark {
      display: block;
    }

    ._colorscheme-dark &.light {
      display: block;
    }

    ._colorscheme-dark &.dark {
      display: none;
    }
  }
}

.positions-viewer {
  width: auto;

  margin-top: 2em;

  > .positions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    background: linear-gradient(180deg, #00000029, #0000001a);
    box-shadow:
      inset 0 -1px 2px -1px #ffffff3b,
      inset 0 -1px 2px -1px #0000003b;

    max-width: 1600px;
    margin: 0 auto;
    gap: 1em 4em;

    @include breakpoint(1600px) {
      padding: 0 1em;
    }
  }

  .tick-details {
    flex-basis: 100%;

    text-align: center;
    padding: 1em;
    font-size: 1.4em;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    border-bottom: 2px solid #ffffff3b;

    @include breakpoint($smallish) {
      flex-flow: row wrap;
    }

    > h3 {
      margin-top: 0;
      flex-basis: 100%;

      @include breakpoint($smallish) {
        margin-bottom: 2em;
      }
    }
  }

  .token-positions {
    padding: 1em;
    text-align: center;

    @include breakpoint($smallish) {
      flex-basis: calc(30% - 2em);
      padding: 0.5em;
    }

    label {
      font-size: 1.4em;
    }
  }

  .owner-positions {
    margin-top: 1em;
    font-size: 0.9em;

    @include breakpoint(770px) {
      font-size: 1.83vw;
    }

    @include breakpoint(1100px) {
      font-size: 1.3em;
    }

    .owner {
      margin-bottom: 1.2em;
      border-bottom: 2px solid #ffffff3b;

      .address {
        overflow-wrap: anywhere;
      }

      .positions {
        margin: 1em 0;
      }

      .position {
        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: 1em;
        margin: 1.2em auto;

        @include breakpoint($smallish) {
          gap: 3em;
        }
      }

      .price-range {
        .sep {
          vertical-align: middle;
          font-size: 0.45em;
          padding: 0 0.4em;
        }
      }

      .locked-token {
        margin-left: 0.5em;
      }
    }
  }
}

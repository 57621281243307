.dex-selector {
  text-align: center;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 2em;

  @include breakpoint($smallish) {
    justify-content: space-between;
  }

  img {
    max-width: 100px;

    @include breakpoint($smallish) {
      max-width: 200px;
    }
  }
}

.history {
  .history-container {
    display: none;
    width: 100%;
    min-height: 70vh;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;

    background: var(--background-color);
    border-radius: 0 0 30px 30px;

    .closer {
      text-align: left;
      font-size: 0.7em;
      margin: 0.3em 0.4em 0;

      .icon {
        font-size: 1.4em;
        vertical-align: middle;
        color: darkred;
        line-height: 1.3;
      }

      &:hover .icon {
        color: darken(darkred, 10%);
      }
    }

    .history-toggled & {
      display: block;
    }
  }

  .history-list {
    margin: 0.7em 0;

    li {
      list-style: none;
      padding: 0;

      padding: 0.5em 0;
      border-bottom: 2px dashed var(--not-primary-color);

      &:first-of-type {
        border-top: 2px dashed var(--not-primary-color);
      }
    }

    a {
      display: block;
      white-space: pre-wrap;
      padding: 0 1em 0.2em;
      list-style: none;
      font-size: 0.9em;
      word-break: break-all;

      @include breakpoint($smallish) {
        font-size: 0.9em;
      }

      &, &:visited {
        color: var(--primary-color);
      }

    }

    time {
      font-size: 0.7em;
      font-style: italic;
    }
  }
}

footer {

  margin: 2em auto 3em;
  text-align: center;

  .credits {
    @include breakpoint($smallish) {
      font-size: 1.3em;
    }
  }

}

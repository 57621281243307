.pool-results {
  padding: 1em;
  border-radius: 20px;

  margin: 0 auto;

  background: linear-gradient(180deg, #00000029, #0000001a);
  box-shadow:
    inset 0 -1px 2px -1px #ffffff3b,
    inset 0 -1px 2px -1px #0000003b;

  &:empty {
    padding: 0;
    border: 0;
  }

  @include breakpoint($smallish) {
    max-width: 1100px;
  }
}

.pool-info {
  width: 100%;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1fr 1fr;
  gap: 0.7em;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding: 0.5em 1em;

  cursor: pointer;

  .sep, &.header {
    display: none;
  }

  &:hover {
    color: var(--not-primary-color);
  }

  @include breakpoint($smallish) {

    flex-flow: row nowrap;

    .sep {
      display: inline;
    }

    &.header {
      display: grid;
    }
  }

  &.header {
    font-size: 0.9em;
    color: var(--not-primary-color);
    cursor: default;
  }

  .label {
    margin: 0.1em 0;
    display: flex;
    align-items: center;

    label {
      margin: 0 0.3em;
      cursor: pointer;
    }
  }

  .volume24, .tvl, .price {
    text-align: center;

    @include breakpoint($smallish) {
      text-align: right;
    }
  }
}

// Breakpoints
$smallish: 800px;
$big: 1100px;

@mixin hidden {
  display: none;
}

.hidden {
  @include hidden;
}

.small {
  font-size: 80%;
}

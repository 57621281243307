.query {
  max-width: 100%;
  padding: 1em 0;

  @include breakpoint($smallish) {
    min-width: 665px;
  }

  .query-summary {
    margin-top: 0;
  }

  .input {
    background: linear-gradient(180deg, #00000029, #0000001a);
    box-shadow:
      inset 0 -1px 2px -1px #ffffff3b,
      inset 0 -1px 2px -1px #0000003b;

    width: 100%;
    font-size: 1.5em;
    padding: 0.3em 0.7em;
    border-radius: 30px;
    text-align: center;

    caret-color: darken(white, 20%);

    &.incorrect-format {
      text-decoration: red wavy underline;
    }

    &:focus {
      outline: 0;
    }
  }

}


[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: var(--not-primary-color);

  cursor: text;
}

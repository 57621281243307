.owners {
  width: 100%;
  margin: 0 auto;

  .owner {
    margin: 0 auto;
    background: #0000002b;
    border-radius: 30px;

    @include breakpoint($big) {
      max-width: 90%;
    }

    &:not(:first-of-type) {
      margin-top: 3em;
    }
  }

  .owner-address {
    font-size: 1.5em;
    text-align: center;
    padding: 0.5em 0.3em;
    border-radius: 30px 30px 0 0;

    background: #00000052;

    overflow-wrap: anywhere;
  }
}

.pair-positions {
  margin: 0 auto;
  width: 100%;

  .position {
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    padding: 1em;
    border-bottom: 2px dashed var(--not-primary-color);

    &:first-of-type {
      border-top: 2px dashed var(--not-primary-color);
    }

    &.closed {
      opacity: .7;
      background: #92929233;
    }

    @include breakpoint($smallish) {
      flex-flow: row nowrap;
    }

    @include breakpoint(max-width $smallish) {
      .sep {
        color: transparent;
        display: block;
        text-align: center;
        margin: 0.3em 0;

        &:before {
          content: 'To';
          font-style: italic;
          position: absolute;
          color: var(--primary-color);
        }
      }
    }
  }

  .position-id {
    flex-basis: 10%;
  }

  .range {
    flex-basis: 10%;

    .in { display: none; }
    .out { display: block; }
    .gone { display: none; }

    &.in-range {
      .in { display: block; }
      .out { display: none; }
    }

    &.closed {
      .gone { display: block; }
      .out { display: none; }
    }
  }

  .price, .liquidity {
    small {
      display: block;
      margin: 0.3em 0;
    }
  }

  .price {
    flex-basis: 55%;
    text-align: center;
    margin: 1em 0;

    @include breakpoint($smallish) {
      margin: 0;
    }

    .sep {
      font-size: 0.7em;

      @include breakpoint($smallish) {
        margin: 0 0.3em;
      }
    }

    .token-price {
      padding-top: 0.4em;

      &:first-of-type {
        border-bottom: 1px dashed var(--not-primary-color);
        padding-bottom: 0.4em;
      }
    }
  }

  .liquidity {
    text-align: center;

    @include breakpoint($smallish) {
      text-align: right;
    }

    .token-liquidity {
      padding-top: 0.4em;

      &:first-of-type {
        border-bottom: 1px dashed var(--not-primary-color);
        padding-bottom: 0.4em;
      }
    }
  }
}

header {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 1600px;

  padding: 0.3em 0;

  @include breakpoint($smallish) {
    padding: 1em 0;

    justify-content: space-between;
    flex-flow: row nowrap;
  }

  &.center {
    justify-content: center;
  }

  .title-img {
    max-width: 40px;

    margin-right: 10px;

    &.uniswap {
      vertical-align: top;
    }

    &.nine-mm {
      max-width: 50px;
    }

    &.nine-inch {
      vertical-align: sub;
      max-width: 90px;
    }
  }

  h1 {
    order: 1;
    font-size: 1.9rem;
    font-weight: 300;
    line-height: 1.5em;
    margin: 0;

    @include breakpoint($smallish) {
      text-align: left;
      font-size: 2rem;
    }

    a {
      color: var(--primary-color);
      text-decoration: none;
    }

    small {
      font-size: 60%;
      margin-right: 0.6em;
      padding: 0.1em 0.6em;

      background: var(--background-color);
    }
  }

  .togglers {
    display: flex;
    flex-flow: row nowrap;
    order: 0;
    gap: 0.4em;

    @include breakpoint($smallish) {
      order: 2;
    }
  }

  .theme-selector, .history {
    font-size: 2em;
    display: inline-block;

    padding: 0.3em 0;

    @include breakpoint($smallish) {
      font-size: 2em;
    }

    .icon {
      vertical-align: middle;
      line-height: 1;
    }

    .toggler {
      cursor: pointer;
      color: var(--primary-color);

      transition: color 0.15s ease;

      &:hover {
        color: var(--not-primary-color);
      }
    }
  }

  @include breakpoint($smallish) {
    .history .icon {
      font-size: 1.3em;
    }
  }
}

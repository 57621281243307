html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

.hidden {
  display: none;
}

.small {
  font-size: 80%;
}

body.history-toggled .world > section, body.history-toggled .world > footer {
  opacity: .4;
}

.world {
  width: 100%;
  flex-flow: column wrap;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 800px) {
  .world {
    width: 97vw;
    place-items: center;
  }
}

.content {
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2em;
  display: flex;
}

.blocks {
  padding: 1em 2em;
  font-size: 1.5em;
}

.blocks .block {
  text-align: center;
  border: 1px solid var(--background-color);
  background: var(--background-color-soft);
  margin-bottom: 2em;
  padding: .5em .7em 1em;
}

.blocks .block .label {
  text-align: center;
  border-bottom: 1px solid var(--background-color);
  margin-bottom: .5em;
  padding-bottom: .3em;
  font-size: 1.5em;
  display: block;
}

.send-tip h3 {
  width: 100%;
  text-align: center;
  background: #0003;
  flex-basis: 100%;
  margin: 0;
  padding: .7em;
  line-height: 1.5;
}

header {
  text-align: center;
  width: 100%;
  max-width: 1600px;
  flex-flow: column;
  justify-content: center;
  padding: .3em 0;
  display: flex;
  position: relative;
}

@media (min-width: 800px) {
  header {
    flex-flow: row;
    justify-content: space-between;
    padding: 1em 0;
  }
}

header.center {
  justify-content: center;
}

header .title-img {
  max-width: 40px;
  margin-right: 10px;
}

header .title-img.uniswap {
  vertical-align: top;
}

header .title-img.nine-mm {
  max-width: 50px;
}

header .title-img.nine-inch {
  vertical-align: sub;
  max-width: 90px;
}

header h1 {
  order: 1;
  margin: 0;
  font-size: 1.9rem;
  font-weight: 300;
  line-height: 1.5em;
}

@media (min-width: 800px) {
  header h1 {
    text-align: left;
    font-size: 2rem;
  }
}

header h1 a {
  color: var(--primary-color);
  text-decoration: none;
}

header h1 small {
  background: var(--background-color);
  margin-right: .6em;
  padding: .1em .6em;
  font-size: 60%;
}

header .togglers {
  flex-flow: row;
  order: 0;
  gap: .4em;
  display: flex;
}

@media (min-width: 800px) {
  header .togglers {
    order: 2;
  }
}

header .theme-selector, header .history {
  padding: .3em 0;
  font-size: 2em;
  display: inline-block;
}

@media (min-width: 800px) {
  header .theme-selector, header .history {
    font-size: 2em;
  }
}

header .theme-selector .icon, header .history .icon {
  vertical-align: middle;
  line-height: 1;
}

header .theme-selector .toggler, header .history .toggler {
  cursor: pointer;
  color: var(--primary-color);
  transition: color .15s;
}

header .theme-selector .toggler:hover, header .history .toggler:hover {
  color: var(--not-primary-color);
}

@media (min-width: 800px) {
  header .history .icon {
    font-size: 1.3em;
  }
}

.history .history-container {
  width: 100%;
  min-height: 70vh;
  z-index: 99;
  background: var(--background-color);
  border-radius: 0 0 30px 30px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.history .history-container .closer {
  text-align: left;
  margin: .3em .4em 0;
  font-size: .7em;
}

.history .history-container .closer .icon {
  vertical-align: middle;
  color: #8b0000;
  font-size: 1.4em;
  line-height: 1.3;
}

.history .history-container .closer:hover .icon {
  color: #580000;
}

.history-toggled .history .history-container {
  display: block;
}

.history .history-list {
  margin: .7em 0;
}

.history .history-list li {
  border-bottom: 2px dashed var(--not-primary-color);
  padding: .5em 0;
  list-style: none;
}

.history .history-list li:first-of-type {
  border-top: 2px dashed var(--not-primary-color);
}

.history .history-list a {
  white-space: pre-wrap;
  word-break: break-all;
  padding: 0 1em .2em;
  font-size: .9em;
  list-style: none;
  display: block;
}

@media (min-width: 800px) {
  .history .history-list a {
    font-size: .9em;
  }
}

.history .history-list a, .history .history-list a:visited {
  color: var(--primary-color);
}

.history .history-list time {
  font-size: .7em;
  font-style: italic;
}

.theme-selector {
  font-size: 2em;
}

@media (min-width: 800px) {
  .theme-selector {
    margin-right: .3em;
  }
}

.theme-selector .themerator {
  cursor: pointer;
}

.theme-selector .themerator i {
  color: var(--primary-color);
}

.theme-selector .themerator.dark, ._colorscheme-light .theme-selector .themerator.light {
  display: none;
}

._colorscheme-light .theme-selector .themerator.dark, ._colorscheme-dark .theme-selector .themerator.light {
  display: block;
}

._colorscheme-dark .theme-selector .themerator.dark {
  display: none;
}

.dex-selector {
  text-align: center;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 2em;
  display: flex;
}

@media (min-width: 800px) {
  .dex-selector {
    justify-content: space-between;
  }
}

.dex-selector img {
  max-width: 100px;
}

@media (min-width: 800px) {
  .dex-selector img {
    max-width: 200px;
  }
}

footer {
  text-align: center;
  margin: 2em auto 3em;
}

@media (min-width: 800px) {
  footer .credits {
    font-size: 1.3em;
  }
}

.query {
  width: 100%;
  padding: 1em 0;
}

.query .input {
  width: 100%;
  text-align: center;
  caret-color: #ccc;
  background: linear-gradient(#00000029, #0000001a);
  border-radius: 30px;
  margin: 0 auto .7em;
  padding: .3em .7em;
  font-size: 1.5em;
  box-shadow: inset 0 -1px 2px -1px #ffffff3b, inset 0 -1px 2px -1px #0000003b;
}

@media (min-width: 800px) {
  .query .input {
    max-width: 665px;
  }
}

.query .input.incorrect-format {
  -webkit-text-decoration: underline wavy red;
  text-decoration: underline wavy red;
}

.query .input:focus {
  outline: 0;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: var(--not-primary-color);
  cursor: text;
}

.pool-results {
  background: linear-gradient(#00000029, #0000001a);
  border-radius: 20px;
  margin: 0 auto;
  padding: 1em;
  box-shadow: inset 0 -1px 2px -1px #ffffff3b, inset 0 -1px 2px -1px #0000003b;
}

.pool-results:empty {
  border: 0;
  padding: 0;
}

@media (min-width: 800px) {
  .pool-results {
    max-width: 1100px;
  }
}

.pool-info {
  width: 100%;
  cursor: pointer;
  grid-template-columns: 1.8fr 1fr 1fr 1fr;
  place-content: center space-between;
  align-items: center;
  gap: .7em;
  padding: .5em 1em;
  display: grid;
}

.pool-info .sep, .pool-info.header {
  display: none;
}

.pool-info:hover {
  color: var(--not-primary-color);
}

@media (min-width: 800px) {
  .pool-info {
    flex-flow: row;
  }

  .pool-info .sep {
    display: inline;
  }

  .pool-info.header {
    display: grid;
  }
}

.pool-info.header {
  color: var(--not-primary-color);
  cursor: default;
  font-size: .9em;
}

.pool-info .label {
  align-items: center;
  margin: .1em 0;
  display: flex;
}

.pool-info .label label {
  cursor: pointer;
  margin: 0 .3em;
}

.pool-info .volume24, .pool-info .tvl, .pool-info .price {
  text-align: center;
}

@media (min-width: 800px) {
  .pool-info .volume24, .pool-info .tvl, .pool-info .price {
    text-align: right;
  }
}

.ticker-icon > object {
  width: 30px;
  height: 30px;
  background: radial-gradient(#fff 50%, #fff0 calc(75% + 1px) 100%);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 1px #000;
}

.ticker-icon > object:first-of-type {
  margin-right: -15px;
}

.ticker-icon > object:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Pro%206.1.1%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20%28Commercial%20License%29%20Copyright%202022%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M512%20256c0%20141.4-114.6%20256-256%20256S0%20397.4%200%20256%20114.6%200%20256%200s256%20114.6%20256%20256zM99.5%20144.8C77.15%20176.1%2064%20214.5%2064%20256c0%20106%2085.1%20192%20192%20192%2041.5%200%2079.9-13.1%20111.2-35.5L99.5%20144.8zM448%20256c0-106.9-86-192-192-192-41.5%200-79.9%2013.15-111.2%2035.5l267.7%20267.7C434.9%20335.9%20448%20297.5%20448%20256z%22%2F%3E%3C%2Fsvg%3E");
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 1px #000;
}

.invert-pair {
  vertical-align: top;
  margin-left: .5em;
  font-size: .6em;
  position: relative;
  top: 5px;
}

.invert-pair .icon {
  margin-right: .3em;
}

.pool-viewer {
  width: 100%;
  background: linear-gradient(#00000029, #0000001a);
  margin-top: 0;
  overflow: hidden;
  box-shadow: inset 0 -1px 2px -1px #ffffff3b, inset 0 -1px 2px -1px #0000003b;
}

.pool-viewer:not(:empty) {
  padding: 1em;
}

.pool-viewer .liquidity-tooltip {
  background: var(--background-color);
  pointer-events: none;
  display: none;
  position: absolute;
}

.pool-viewer .liquidity-tooltip:not(:empty) {
  padding: .7em;
}

.pool-viewer .liquidity-chart {
  max-height: 80vh;
}

.pool-viewer .liquidity-chart:hover + .liquidity-tooltip {
  display: block;
}

.pool-description {
  place-content: space-between;
  place-items: center;
  margin: 0 auto;
  font-size: .9em;
  display: flex;
}

@media (min-width: 800px) {
  .pool-description {
    font-size: 1.2em;
  }
}

.pool-description .label {
  align-self: center;
  align-items: center;
  margin: .1em 0;
  font-size: 1em;
  display: flex;
}

@media (min-width: 800px) {
  .pool-description .label {
    margin: .1em 6em .1em .1em;
    font-size: 1.5em;
  }
}

.pool-description .label label {
  margin: 0 .3em;
}

.pool-description .figures div:first-of-type, .pool-description .prices div:first-of-type {
  margin-bottom: .4em;
}

.pool .liquidity-chart {
  margin-top: 2em;
}

.positions-viewer {
  width: auto;
  margin-top: 2em;
}

.positions-viewer > .positions {
  max-width: 1600px;
  background: linear-gradient(#00000029, #0000001a);
  flex-flow: wrap;
  justify-content: space-between;
  gap: 1em 4em;
  margin: 0 auto;
  display: flex;
  box-shadow: inset 0 -1px 2px -1px #ffffff3b, inset 0 -1px 2px -1px #0000003b;
}

@media (min-width: 1600px) {
  .positions-viewer > .positions {
    padding: 0 1em;
  }
}

.positions-viewer .tick-details {
  text-align: center;
  border-bottom: 2px solid #ffffff3b;
  flex-flow: column;
  flex-basis: 100%;
  justify-content: space-between;
  padding: 1em;
  font-size: 1.4em;
  display: flex;
}

@media (min-width: 800px) {
  .positions-viewer .tick-details {
    flex-flow: wrap;
  }
}

.positions-viewer .tick-details > h3 {
  flex-basis: 100%;
  margin-top: 0;
}

@media (min-width: 800px) {
  .positions-viewer .tick-details > h3 {
    margin-bottom: 2em;
  }
}

.positions-viewer .token-positions {
  text-align: center;
  padding: 1em;
}

@media (min-width: 800px) {
  .positions-viewer .token-positions {
    flex-basis: calc(30% - 2em);
    padding: .5em;
  }
}

.positions-viewer .token-positions label {
  font-size: 1.4em;
}

.positions-viewer .owner-positions {
  margin-top: 1em;
  font-size: .9em;
}

@media (min-width: 770px) {
  .positions-viewer .owner-positions {
    font-size: 1.83vw;
  }
}

@media (min-width: 1100px) {
  .positions-viewer .owner-positions {
    font-size: 1.3em;
  }
}

.positions-viewer .owner-positions .owner {
  border-bottom: 2px solid #ffffff3b;
  margin-bottom: 1.2em;
}

.positions-viewer .owner-positions .owner .address {
  overflow-wrap: anywhere;
}

.positions-viewer .owner-positions .owner .positions {
  margin: 1em 0;
}

.positions-viewer .owner-positions .owner .position {
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  margin: 1.2em auto;
  display: flex;
}

@media (min-width: 800px) {
  .positions-viewer .owner-positions .owner .position {
    gap: 3em;
  }
}

.positions-viewer .owner-positions .owner .price-range .sep {
  vertical-align: middle;
  padding: 0 .4em;
  font-size: .45em;
}

.positions-viewer .owner-positions .owner .locked-token {
  margin-left: .5em;
}

.query {
  max-width: 100%;
  padding: 1em 0;
}

@media (min-width: 800px) {
  .query {
    min-width: 665px;
  }
}

.query .query-summary {
  margin-top: 0;
}

.query .input {
  width: 100%;
  text-align: center;
  caret-color: #ccc;
  background: linear-gradient(#00000029, #0000001a);
  border-radius: 30px;
  padding: .3em .7em;
  font-size: 1.5em;
  box-shadow: inset 0 -1px 2px -1px #ffffff3b, inset 0 -1px 2px -1px #0000003b;
}

.query .input.incorrect-format {
  -webkit-text-decoration: underline wavy red;
  text-decoration: underline wavy red;
}

.query .input:focus {
  outline: 0;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: var(--not-primary-color);
  cursor: text;
}

.owners {
  width: 100%;
  margin: 0 auto;
}

.owners .owner {
  background: #0000002b;
  border-radius: 30px;
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .owners .owner {
    max-width: 90%;
  }
}

.owners .owner:not(:first-of-type) {
  margin-top: 3em;
}

.owners .owner-address {
  text-align: center;
  overflow-wrap: anywhere;
  background: #00000052;
  border-radius: 30px 30px 0 0;
  padding: .5em .3em;
  font-size: 1.5em;
}

.pair {
  width: 100%;
  padding-bottom: 2em;
  font-size: 1.2em;
}

.pair:last-of-type {
  padding-bottom: 0;
}

.pair:last-of-type .pair-positions {
  padding-bottom: 1.5em;
}

@media (min-width: 800px) {
  .pair {
    font-size: 1.3em;
  }
}

.pair .pair-info {
  width: 100%;
  flex-flow: column wrap;
  place-content: center space-between;
  align-items: center;
  padding: .5em 1em;
  display: flex;
}

.pair .pair-info .sep {
  display: inline;
}

@media (min-width: 800px) {
  .pair .pair-info {
    flex-flow: row;
  }
}

.pair .pair-info .labels {
  align-items: center;
  margin: .1em 0;
  display: flex;
}

.pair .pair-info .labels label {
  margin: 0 .3em;
}

.pair .pair-info .prices {
  text-align: center;
  margin: .4em 0;
  font-size: .9em;
}

@media (min-width: 800px) {
  .pair .pair-info .prices {
    text-align: right;
    margin: 0;
  }
}

@media (max-width: 800px) {
  .pair .pair-info .prices .price > .sep {
    display: block;
  }
}

.pair .pair-info .token-icon > object {
  width: 30px;
  height: 30px;
  background: radial-gradient(#fff 50%, #fff0 calc(75% + 1px) 100%);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 1px #000;
}

.pair .pair-info .token-icon > object:first-of-type {
  margin-right: -15px;
}

.pair .pair-info .token-icon > object:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Pro%206.1.1%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20%28Commercial%20License%29%20Copyright%202022%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M512%20256c0%20141.4-114.6%20256-256%20256S0%20397.4%200%20256%20114.6%200%20256%200s256%20114.6%20256%20256zM99.5%20144.8C77.15%20176.1%2064%20214.5%2064%20256c0%20106%2085.1%20192%20192%20192%2041.5%200%2079.9-13.1%20111.2-35.5L99.5%20144.8zM448%20256c0-106.9-86-192-192-192-41.5%200-79.9%2013.15-111.2%2035.5l267.7%20267.7C434.9%20335.9%20448%20297.5%20448%20256z%22%2F%3E%3C%2Fsvg%3E");
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 1px #000;
}

.pair-positions {
  width: 100%;
  margin: 0 auto;
}

.pair-positions .position {
  border-bottom: 2px dashed var(--not-primary-color);
  flex-flow: column wrap;
  place-content: center space-between;
  align-items: center;
  padding: 1em;
  display: flex;
}

.pair-positions .position:first-of-type {
  border-top: 2px dashed var(--not-primary-color);
}

.pair-positions .position.closed {
  opacity: .7;
  background: #92929233;
}

@media (min-width: 800px) {
  .pair-positions .position {
    flex-flow: row;
  }
}

@media (max-width: 800px) {
  .pair-positions .position .sep {
    color: #0000;
    text-align: center;
    margin: .3em 0;
    display: block;
  }

  .pair-positions .position .sep:before {
    content: "To";
    color: var(--primary-color);
    font-style: italic;
    position: absolute;
  }
}

.pair-positions .position-id, .pair-positions .range {
  flex-basis: 10%;
}

.pair-positions .range .in {
  display: none;
}

.pair-positions .range .out {
  display: block;
}

.pair-positions .range .gone {
  display: none;
}

.pair-positions .range.in-range .in {
  display: block;
}

.pair-positions .range.in-range .out {
  display: none;
}

.pair-positions .range.closed .gone {
  display: block;
}

.pair-positions .range.closed .out {
  display: none;
}

.pair-positions .price small, .pair-positions .liquidity small {
  margin: .3em 0;
  display: block;
}

.pair-positions .price {
  text-align: center;
  flex-basis: 55%;
  margin: 1em 0;
}

@media (min-width: 800px) {
  .pair-positions .price {
    margin: 0;
  }
}

.pair-positions .price .sep {
  font-size: .7em;
}

@media (min-width: 800px) {
  .pair-positions .price .sep {
    margin: 0 .3em;
  }
}

.pair-positions .price .token-price {
  padding-top: .4em;
}

.pair-positions .price .token-price:first-of-type {
  border-bottom: 1px dashed var(--not-primary-color);
  padding-bottom: .4em;
}

.pair-positions .liquidity {
  text-align: center;
}

@media (min-width: 800px) {
  .pair-positions .liquidity {
    text-align: right;
  }
}

.pair-positions .liquidity .token-liquidity {
  padding-top: .4em;
}

.pair-positions .liquidity .token-liquidity:first-of-type {
  border-bottom: 1px dashed var(--not-primary-color);
  padding-bottom: .4em;
}

/*# sourceMappingURL=index.7cd28b59.css.map */

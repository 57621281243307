@import 'vendor/normalize';
@import 'vendor/main';
@import 'breakpoint-sass';

@import 'mixins';

body.history-toggled {
  .world > section, .world > footer {
    opacity: .4;
  }
}

.world {
  display: flex;
  flex-flow: column wrap;

  margin: 0 auto;
  width: 100%;

  @include breakpoint($smallish) {
    width: 97vw;
    place-items: center;
  }
}

.content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  gap: 2em;
}

.blocks {
  font-size: 1.5em;

  padding: 1em 2em;

  .block {
    text-align: center;
    margin-bottom: 2em;
    border: 1px solid var(--background-color);
    background: var(--background-color-soft);

    padding: 0.5em 0.7em 1em;

    .label {
      display: block;
      text-align: center;
      font-size: 1.5em;
      border-bottom: 1px solid var(--background-color);
      padding-bottom: 0.3em;
      margin-bottom: 0.5em;
    }
  }

}

.send-tip h3 {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0.7em;
  flex-basis: 100%;
  line-height: 1.5;
  background: rgba(black, .2);
}


@import 'sections/header';
@import 'sections/history';
@import 'sections/theme-selector';
@import 'sections/dex-selector';
@import 'sections/footer';

@import 'liquidity/query';
@import 'liquidity/pool-results';
@import 'liquidity/pool';
@import 'liquidity/positions';

@import 'positions/query';
@import 'positions/owners';
@import 'positions/pair';
@import 'positions/positions';


.pair {
  width: 100%;

  font-size: 1.2em;

  padding-bottom: 2em;

  &:last-of-type {
    padding-bottom: 0;

    .pair-positions {
      padding-bottom: 1.5em;
    }

  }

  @include breakpoint($smallish) {
    font-size: 1.3em;
  }

  .pair-info {
    display: flex;
    width: 100%;
    padding: 0.5em 1em;
    flex-flow: column wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .sep {
      display: inline;
    }

    @include breakpoint($smallish) {
      flex-flow: row nowrap;
    }

    .labels {
      margin: 0.1em 0;
      display: flex;
      align-items: center;

      label {
        margin: 0 0.3em;
      }
    }

    .prices {
      font-size: 0.9em;
      text-align: center;
      margin: 0.4em 0;

      @include breakpoint(800px) {
        margin: 0;
        text-align: right;
      }

      .price > .sep {

        @include breakpoint(max-width 800px) {
          display: block;
        }
      }
    }

    .token-icon {
      > object {
        width: 30px;
        height: 30px;

        display: inline-block;
        position: relative;
        background: radial-gradient(white 50%, rgba(255, 255, 255, 0) calc(75% + 1px), rgba(255, 255, 255, 0) 100%);
        border-radius: 50%;
        box-shadow: black 0px 0px 1px;

        &:first-of-type {
          margin-right: -15px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          box-shadow: black 0px 0px 1px;
          background: white url(data-url:../assets/images/not-found.svg);
        }
      }
    }
  }
}
